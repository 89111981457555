.footInner {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;

    @include bp($bp-m) {
        align-items: flex-start;
        flex-direction: row;
        padding: 80px 20px;
    }

    .logo {
        margin-left: 0;
    }

    .logoTitle {
        color: $textDark;
    }
}


.footerLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include bp($bp-m) {
        align-items: flex-start;
    }
}

.siteBy {  
    font-size: 0.9em;

    a {
        color: $textDark;
    }
}

.footerRight {
    display: flex;
    align-items: flex-start;
    flex-wrap:wrap;
    margin-bottom: 50px;

    a {
        color: $textDark;
    }
    @include bp($bp-s) {
        height: 170px;
    }

    @include bp($bp-m) {
        margin: 0;
    }
}

.footerAddress {
    line-height: 1.3em;

    @include bp($bp-xs) {
        padding-right: 50px;
        margin-right: 50px;
        border-right: 1px solid $mainColor;
    }
}

.footHeader {
    text-transform: uppercase;
    color: $mainColor;
}

.footerMenu {
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    min-width: 260px;

    @include bp($bp-xs) {
        display: flex;
    }
}

.footerItem {
    text-transform: uppercase;
    line-height: 1.8em;
    font-size: 1em;
    margin-right: 20px;
    a {
        line-height: 1.1em;
    }
}

.slogan {
    display: block;
    font-family: $font-trajan;
    letter-spacing: 16px;
    color: $lightGrey;
    font-size: 2em;

    @include bp($bp-m) {
        letter-spacing: 20px;
        font-size: 3em;
    }

    @include bp($bp-lg) {
        font-size: 5em;
    }
}