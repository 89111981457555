/**
 * This typography file is included in the WYSIWYG editor
 */
.typography * {
}
.typography blockquote {
	margin-left:20px;
}
.typography hr {
	padding:5px;
	clear: both;
}
p,
ul li,
ol li,
a {
	
	line-height:1.5em;
	font-size:1.1em;
}
p {
	margin-bottom:20px;
}


/** CMS LIST STYLES **/
.typography ul,
.typography ol {
	margin-left:10px;
	margin-bottom:20px;
}
	.typography ul li,
	.typography ol li {
		margin:4px 10px;
		line-height:1.4em;
	}
		.typography ul li {
		 list-style-type:disc;
		}
		.typography ol li {
			list-style-type:decimal;
    }
/* HEADER STYLES */

h1,
h2,
h3,
h4 {
	margin-bottom:14px;
	color: $headingColor;
	font-family: $font-trajan;
}
h1,
h4 {
	font-weight:500;
}
h2,
h3 {
	font-weight:normal;
}
	h1,
	h2.pageTitle {
		font-size:2.1em;
		position: relative;
		text-align: center;
		padding-bottom: 20px;
		letter-spacing: 4px;
		line-height: 1.3em;
		@include bp($bp-xs) {
			font-size:2.2em;
			letter-spacing: 9px;
		}
		@include bp($bp-m) {
			letter-spacing: 18px;
		}
		&:after {
			content: '';
			width: 25%;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			border-bottom: 1px solid $themeColor;
		}
	}
	h2 {
		font-size:2em;
		line-height: 1em;
		letter-spacing: 4px;

		@include bp($bp-xs) {
			font-size:2.2em;
			letter-spacing: 9px;
		}

		@include bp($bp-m) {
			letter-spacing: 18px;
		}
	}
	h3 {
		font-size:1.6em;
	}
	h4 {
		font-size:1.2em;
		color: $mainColor;
		margin-bottom: 10px;
		font-family: $font-tenez;
	}

/* LINK STYLES */
.typography a {
	color: $mainColor2;
  	font-size:1em;
  	text-decoration:none;
}
	.typography a:hover {
		text-decoration:underline;
	}
	
	
.typography table {
	font-size:1.1em;
	border: 1px solid;
	margin: 10px 0;

	tr:nth-child(even) {
		background-color: rgba(36, 43, 146, 0.3);
	}
}
	.typography table tr td {
		padding:3px 5px;
	}

.btn,
.typography .btn, {
	background: $mainColor;
	display: inline-block;
	text-transform: uppercase;
	text-align: center;
	padding: 5px 25px;
	color: #fff;
	a {
		color: #fff;
	}
}
	
/* WYSIWYG EDITOR ALIGNMENT CLASSES */
.typography .left {
	text-align:left;
}
.typography .center {
	text-align:center;
}
.typography img.center {
	display: inline-block;	
}
.typography .right {
	text-align:right;
}
.typography img.right {
	float:right;
}
.typography img.left {
	float:left;
}
.typography img {
	max-width: 100%;	
}
.typography p.MsoNormal, .typography p.MsoBodyText {
	margin: 0;
}

.whiteText {
	color: #fff;
}

.greyText {
	color: $textGrey;
	
}

a.greyText {
	&:hover {
		color: $mainColor;
	}
}

.goldText {
	color: $themeColor;
}

.blueText {
	color: $mainColor;
}
