#header{
	position: absolute;
	z-index: 100;
	padding-bottom: 1px;
	width: 100%;

	&:after {
		content: '';
		border-bottom: 1px solid $themeColor;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0%;
		z-index: -1;
		@include transition($t2);

		.pace-done & {
			width: 100%;
		}
	}
}

.headInner {
	padding: 50px 15px 0;
	position: relative;

	@include bp($bp-s) {
		padding: 20px 30px 0;
	}

	@include bp($bp-mlg) {
		padding: 20px 50px 0;
	}
}

.logo{
	width: 290px;
	margin-left: 0;
	display: flex;
	align-items: flex-end;
	padding-bottom: 15px;
	overflow: hidden;

	@include bp($bp-mlg) {
		width: 400px;
		margin-left: 50px;
	}
}

.logoImg {
	width: 25%;
}

.logoTitle {
	font-family: $font-poynter;
	color: #fff;
	font-size: 23px;
	line-height: 29px;
	letter-spacing: normal;
    padding-bottom: 0;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 0;
	text-align: left;
	width: 75%;

	&:after {
		display: none;
	}

	.logoMain {
		font-size: 35px;
		.firstLetter { 
			font-size: 47px;
		}

		@include bp($bp-mlg) {
			font-size: 48px;
		.firstLetter { 
			font-size: 60px;
		}

		}
	}

	.logoSub {
		.firstLetter {
			font-size: 35px;
			@include bp($bp-mlg) {
				font-size: 48px;
			}
		}
	}

	@include bp($bp-mlg) {
		font-size: 36px;
		line-height: 38px;
	}
}

.headInner {
	display: flex;
	justify-content: space-between;
    align-items: flex-end;
}

.additionalNav {
	position: absolute;
	top:0;
	right:0;
	display: flex;
	align-items: flex-start;
	z-index: 99999;
}

#searchLink {
	color: $mainColor;
	display: block;
	background-color: #fff;
	padding: 15px 11px 10px 10px;
	margin-right: 20px;
	font-size: 1em;
	position: relative;
	overflow: hidden;

	.fa-times {
		position: absolute;
		top: 40%;
		left: 35%;
		transform: translateY(200%);
		@include transition($t1);
	}

	.fa-search {
		transform: translateY(0%);
		@include transition($t1);
	}

	&.active {
		.fa-times {
			transform: translateY(0%);
		}
		.fa-search {
			transform: translateY(200%);
		}
	}
}

/*Search styles*/ //**change template - update styles
.searchToggle{
	cursor: pointer;
	z-index: 8000;
	*{
		color: #fff;
	}
	position: fixed;
	top: 5px;
	left: 10px;
	span{
	display: inline-block;
	margin-left: 10px;
	}
}
.searchBlackout{
	position: fixed;
	top: 0px; 
	left: 0px;
	width: 100%;
	display: none;
	height: 100%;
	z-index: 99999;
	background-color: rgba(0,0,0,0.6);
}
.searchContainer{
	display: flex;
	align-items: center;
    justify-content: center;
    top: 0;
	width: 100%;
	max-height: 0px;
	overflow: hidden;
    padding: 0;
	z-index: 1002;
	position: relative;
	background: #fff;
	
	@include transition($t1);

	&.active {
		max-height: 60px;
		padding: 9px;
	}

	form{
		width: 560px;
		max-width: 100%;
		display: inline-block;

		fieldset {
			border-bottom: 1px solid #5f5e6d;
		}

		.field {
			width: 90%;
		}
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
	} 
	
	.middleColumn {
		width: 100%;
	}
 
    button{
		border: none !important;
		padding: 5px 0 5px 10px;
		background: none;
		cursor: pointer;
		border-radius: 0px !important;
		float: right;
		display: block;
 

	    i{
			color: $mainColor;
	    }
	    float: right;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: $themeColor;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 1px;
		color: #5f5e6d;
		background: #fff;
	    float: left;
    	@include border-radius(3px !important);
		display: block;
		width: 100%;
	}
	
	#searchClose {
		display: inline-block;
		color: $mainColor;
	}
} 

/* Useful Links */
#usefulLinkOpen {
	font-family: $font-stack;
	font-weight: 500;
	display: flex;
	flex-direction: row-reverse;
	text-transform: uppercase;
	color: #fff;
	font-size: 1.1em;
	background: darken($mainColor, 10%);
	padding: 20px 20px 10px;
	border-bottom: 2px solid darken($themeColor, 5%);
	
	&:before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		color: $themeColor;
		padding: 0 10px;
	}
}
#linkList {
	max-height: 0px;
	overflow: hidden;
	background: $mainColor;
	@include transition($t1);
	&.active {
		max-height: 300px;
	}
}

.usefulLink {
	padding: 5px 20px;
	color: #fff;
	font-size: 0.9em;
	display: block;
	&:hover {
		background: darken($mainColor, 10%);
	}
}

/* Banner Styles */
.PageResults,
.InnerPage{
	.banner{
		&.academy {
			@include bp($bp-xs) {
				height: 70vh;
			}
		}

		@include bp($bp-xs) {
			height: 66vh;
		}
	}
}

.banner{
	position: relative;
	z-index: 2;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/bg.jpg"));

	

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
		background-position: center top;
	}
}

.bannerGrad{
	@include stretchCover(rgba(43,43,43, 0.5), absolute, 1);
	background: linear-gradient(to bottom,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 2%,rgba(0,0,0,0) 40%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.7) 100%);
	.academy & {
		&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(darken($mainColor, 30%), 0.5);
		mix-blend-mode: multiply;
		}

		&:after {
			content: '';
			position: absolute;
			background: url(../images/watermark.png) no-repeat bottom left/100% auto;
			width: 350px;
			height: 380px;
			bottom: -5px;
			left: -50px;
		}
	} 
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}

.academy {
	border-bottom: 10px solid $mainColor;
}


#google_translate_element{
    border: 30px solid #312d5e;
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
	@include transform($center);
	display: none;
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    bottom: 85px;
    left: 50%;
	@include transform(translateX(-50%));
    z-index: 2;
    color: #fff;
	font-size: 1.7em;
	font-family: $font-trajan;
	letter-spacing: 2px;
    display: block;
    opacity: 1;
    line-height: 1.2em;
	text-transform: uppercase;
	font-family: $font-trajan;
    *{
    	color: #fff !important;
	}

	@include bp($bp-xs){
		letter-spacing: 9px;
		font-size: 1.8em;
		white-space: nowrap;
	}

	@include bp($bp-m){
		bottom: 250px;
		letter-spacing: 18px;
		font-size: 2em;
	}
	
	@include bp($bp-lg) {
		font-size: 3em;
	}
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}


#ScrollDown {
	display: block;
	z-index: 12;
	position: absolute;
	font-family: $font-stack;
	text-transform: uppercase;
	text-align: center;
	font-size: 1.3em;
	width: 150px;
	bottom: 10px;
	left:50%;
	@include transform(translateX(-50%));
	

	i {
		font-size: 2;
		@include transform(translateY(0px));
		@include transition($t1);
		@include bp($bp-lg) {
			font-size: 2.5em;
		}
	}
	span {
		display: block;
		line-height: 1em;
	}

	@include bp($bp-m){
		bottom: 170px;
	}

	&:hover {
		i {
			@include transform(translateY(5px));
		}
	}
}
/*End banner styles*/

/*Breadcrumbs styles*/
#breadcrumbs {
	position: absolute;
	bottom: 30px;
	text-align: center;
	z-index: 12;
	color: $themeColor;
	font-size: 1.2em;

	@include bp($bp-m) {
		bottom: 120px;
	}

	.here {
		font-family: $font-poynter;
	}
}
/*End Breadcrumbs styles*/

/*** Quick Links Submenu ***/
.BannerMenu {
	position: absolute;
	bottom: 0;
	z-index: 2;
	width: 100%;
	display: none;
	
	@include bp($bp-m){
		display: flex;
	}

	.BannerMenuItem {
		flex-basis: 25%;
		text-align: center;
		border: 1px solid $themeColor;
		border-bottom: none;
		display: flex;
		align-items: center;
		transform: translateY(100%);

		.Loading & {
			transform: translateY(0);
		}

		.pace-done & {
			transform: translateY(100%);
			@include transition($t1);
			animation: slideUp 0.5s ease-in 0.6s forwards;
		}

		&:first-child {
			border-left: none;
		}

		&:last-child {
			border-right: none;
		}

		a {
			flex-grow: 1;
			display: block;
			padding: 20px;
			color: #fff;
			font-family: $font-poynter;
			font-size: 1.2em;
			background: rgba(0,0,0,0) url(../images/watermark_small.png) no-repeat center/50px auto;
			@include transition($t1);

			@include bp($bp-lg) {
				padding: 30px;
			}

			&:hover {
				background-color: rgba(0,0,0,0.3);
			}
		}
	}
}
/*** Quick Links Submenu ***/

