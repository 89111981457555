.mainContent { //** Change Template
    input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
		font-family: $font-stack;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}

	.checkbox {
		padding-bottom: 10px;
	}
}