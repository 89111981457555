#Home2{
    padding: 90px 0;
    .inner {
        padding: 0 20px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        &:after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background: url(../images/watermark.png) no-repeat top right/350px auto;
            right: 20px;
            top: -25px;
        }
        * {
            position: relative;
            z-index: 2;
        }

        .welcomeHeading {
            margin-bottom: 0;

            .PageLoaded & {
                opacity: 0;
            }
            
            &.activeScroll {
                animation: fadeIn 0.2s ease-in 0s forwards;
            }
        }

        .welcomeContent {
            padding: 40px 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 865px;
            max-width: 100%;
            margin: 0 auto;

            @include bp($bp-m) {
                justify-content: space-between;
                padding: 90px 0;
            }
        }

        .welcomeImage {
            width: 280px;
            padding-top: 5px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .PageLoaded & {
                transform: translateX(-30%);
                opacity: 0;
            }

            &.activeScroll {
                animation: slideRight 0.2s ease-in 0.2s forwards;
            }

            @include bp($bp-m) {
                margin-bottom: 0;
            }
        }

        .welcomeText {
            width: 100%;
            font-size: 1.2em;

            .PageLoaded & {
                transform: translateX(30%);
                opacity: 0;
            }

            &.activeScroll {
                animation: slideLeft 0.2s ease-in 0.5s forwards;
            }
            p {
                margin-bottom: 30px;
            }

            @include bp($bp-m) {
                width: 500px;
                max-height: 340px;
                overflow-y: scroll;
                padding-right: 10px;
            }
        }
    }
    
    .btn {
        display: none;
        @include bp($bp-m) {
            display: inline-block;
        }
    }
}

/* Calendar widget */
#upcomingEvents {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255,255,255,0.8);
    }
}
#homepage-events {	
    padding:100px 50px;
    z-index: 3;
}

.allLink {
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 1px solid $themeColor;
    color: $textDark;
}

.eventsWrapper {
    /*background-color:rgba(255,255,255,0.9);*/
    width:100%;
    max-width:1280px;
    margin:0 auto;
    display:flex;
    justify-content: space-between;
    position: relative;
    padding-top: 60px;   
    flex-wrap: wrap;
}

.calLeft, .calRight {
    width: 100%;
    
    @include bp($bp-m) {
        width: 44%;
    }
}

.calLeft {
    .headItems {
        padding: 15px 0 5px;
        margin-bottom: 25px;
    }
}

.calRight {
    display: none;
    @include bp($bp-m) {
        display: block;
    }
}

.headItems {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.calNav {
    .bx-next, 
    .bx-prev {    
        font-size: 1.4em;
        color: $textDark;
    }
}

.upcomingTitle {
    color: #333;
    font-family: $font-poynter;
    text-transform: uppercase;
    font-size: 1.8em;
    margin-bottom: 0;
}

#sliderUpcoming {
    width:100%;
    margin:0 auto;
    
    section {
        margin-bottom:10px;
        position: relative;
        overflow: hidden;

        &.event-1 {
            .date, 
            .dateOverlay {
                background-color: $themeColor;
            }

            .goldText {
                color: $mainColor;
            }
        }

        &.event-2 {
            .date, 
            .dateOverlay {
                background-color: $mainColor;
            }
        }

        &.event-0 {
            .date, 
            .dateOverlay {
                background-color: $mainColor2;
            }
        }

        &:hover .dateOverlay {
            @include transform(translateY(0%))
        }
    }

    .sliderContent {
        display: flex;
        @include transition($t1)
    }
    .title, 
    .date {
        width: 100%;
        color:#FFFFFF;
        font-size: 18px;
        font-weight: 100;
    }
    .title {
        margin-bottom:80px;
        font-size:18px;
    }
    .date {
        font-weight: 700;
        font-size: 14px;
        padding: 20px;
        text-align: center;
        flex-basis: 100px;
        font-family: $font-poynter;
        a {
            color: #fff;
            line-height: inherit;
            font-size: inherit;
        }
        .day {
            font-size:50px;
            width:100%;
            font-weight:100;
        }
        .month {
            width:100%;
            font-weight:100;
            font-size:20px;
        }

    }

    .calendarTitle {
        display: block;
        padding: 20px 40px;
        margin-left: 2%;
        width: 78%;
        text-align: left;
        background: #fff;
        color: $textDark;
        font-family: $font-poynter;
    }
    .dateOverlay {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%; 
        @include transition($t1);
        @include transform(translateY(-100%));
        @include bp($bp-xs) {
            width: 74.5%;
        }
        a {
            color:#FFFFFF;
            @include transition($t1);
        }
    }
    
}


/*News & Twitter*/
#socialBox {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    min-height: 500px;
    flex-wrap: wrap;
    

    .bx-wrapper {
        height: 100%;
    }
    .bx-viewport {
        height: 100%;
    }
}

.newsBox, 
.twitterBox {
    width: 100%;
    padding: 60px;
    position: relative;
    background-position: center;
    background-size: cover;

    @include bp($bp-m) {
        width: 49.5%;
        padding: 100px;
    }
    
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    * {
        position: relative;
        z-index: 2;
    }
    .allLink {
        color: #fff;
    }
}

.socialSlider {
    width: 100%;
    height: 100%;
    
}
.socialSlide {    
    height: 300px;
    @include bp($bp-m) {
        height: 100%;
    }
}

.slideContents {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
}

.newsBox {
    &:after {
        background-color: rgba($mainColor, 0.7);
    }
}

.twitterBox {
    &:after {
        background-color: rgba(darken($mainColor3, 30%), 0.7);
    }
}

.socialContent {
    color: #fff;
    display: block;
    font-size: 1.3em;
    line-height: 1.2em;
    font-family: $font-poynter;
    margin-bottom: 50px;

    @include bp($bp-s) {
        font-size: 1.7em;
    }
}

/*Newsletters*/
#newsletters {
    padding: 60px 0;
}

#newsletterSlideContainer {
    padding: 0 50px;
    width: 1600px;
    margin: 50px auto 0;
    max-width: 100%;
    position: relative;
}

.newsletter {
    position: relative; 
    display: block;
    text-align: left;

    a {
        display: block;
        padding: 20px 75px 20px 20px;
        color: $textDark;
        font-size: 1.3em;
        background: rgba($mainColor, 0.2) url(../images/watermark_dark.png) no-repeat right center/contain;
        font-family: $font-poynter;
        @include transition($t1);
        &:hover {
            color: #fff;
            background-color: $mainColor;
        }
    }
    
}

.sliderNav {
    position: absolute;
    font-size: 2em;
    top: 50%;
    transform: translateY(-50%);

    .bx-next,
    .bx-prev {
        color: $lightGrey;
    }
}

.sliderPrev {
    left: 5px;
}

.sliderNext {
    right: 5px;
}

/*Notices*/ 
.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	background-color: #dedede;
	max-width: 100%;
    width: 700px;
    border-bottom: 4px solid $themeColor;

    .bx-pager {
        display: flex;
    }

    .bx-pager-item a {
        display: block;
        background: $lightGrey;
        border-radius: 50%;
        text-indent: -9999px;
        width: 10px;
        height: 10px;
        margin-right: 15px;

        &.active,
        &.hover {
            background: $mainColor;
        }
    }
}

.noticeHeader {
    padding: 15px 30px;
    background: $mainColor;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-poynter;
}
.noticeTitle{
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;

}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 30px;
    background: #fff url(../images/watermark.png) no-repeat right bottom/contain;
	color: #333;
    h3 {
        color: $mainColor;
        font-family: $font-poynter;
    }
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}