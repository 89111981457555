/*** Simple Menu CSS ***/
#navContainer {
  font-size: 1.1em;
  display: none;

  @include bp($bp-lg) {
    display: block;
  }
}
.LevelOneMenu{
	position: relative;
	display: block;
	text-align: center;
	font-size: 1em;
	box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	*{
		font-size: 1em;
		box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}
	& > li,ul{
		margin: 0px;
		padding: 0px;
	}
}
.LevelOneMenuItem{
	display: inline-block;
	position: relative;
  vertical-align: middle;
  font-family: $font-poynter;
	&:hover{

		.LevelTwoMenu{
			display: block;
    }
    
    .LevelOneMenuLink:before {
      opacity: 1;
    }
  }
  
  
}
.LevelOneMenuLink{
	display: block;
	position: relative;
	width: 100%;
	float: left;
	clear: both;
  padding: 10px 7px;

  @include bp($bp-mlg) {
    padding: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: url(../images/icon-yellow.png) center/cover;
    top: -5px;
    left: 50%;
    opacity: 0;
    @include transition($t1);
    transform: translateX(-50%);
  }
}
.LevelTwoMenu{
	position: absolute;
	display: none;
	left: 0;
	min-width: 250px;
	bottom: 0px;
  @include transform(translateY(100%));
  .LevelOneMenuItem:nth-last-child(1) &,
  .LevelOneMenuItem:nth-last-child(2) &,
  .LevelOneMenuItem:nth-last-child(3) &{
    left: auto;
    right: 0;
  }
}
.LevelTwoMenuItem{  
	display: block;
	position: relative;
	width: 100%;
  float: left;
  margin: 0;
 
  i {
    padding: 0 20px;
    font-size: 1.3em;
    color: $themeColor;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 12px;
    @include transition($t1);

    &.active {
      transform: rotate(180deg);
    }
  }
}
.LevelTwoMenuLink{
	text-align: left;
	display: block;
	position: relative;
  width: 100%;
	float: left;
	clear: both;
  padding: 10px 55px 10px 0px;
  line-height: 1.1em;
  color: #fff;
  &::before {
    content: '/';
    width: 0;
    display: inline-block;
    overflow: hidden;
    transition: 0.3s;
    padding-right: 0px;
    transform: translateY(2px);
  }
  &:hover {
    color: $themeColor;
    &::before {
      width: 10px;
      padding-right: 10px;
    }
  }
}

.LevelThreeMenu{
	width: 100%;
  max-height: 0;
  overflow: hidden;
  z-index: 2;

  &.active {
    max-height: 500px;
  }
}
.LevelThreeMenuItem{  
	display: block;
	position: relative;
	width: 100%;
  float: left;
  margin: 2px 0 2px;
}
.LevelThreeMenuLink{
	text-align: left;
	display: block;
	position: relative;
	width: 100%;
	float: left;
	clear: both;
  padding: 5px 10px;
  line-height: 1.1em;
  color: #fff;
  &::before {
    content: '/';
    display: inline-block;
    overflow: hidden;
    padding-right: 10px;
    transform: translateY(3px);
    color: $themeColor;
  }
  &:hover {
    color: $themeColor;
  }
}

.WideMenu{
	.LevelTwoMenu {
    	width: 400px;
      padding: 15px;
      background: rgba($mainColor2, 0.9);
	}
	.LevelTwoMenuItem{
		width: 100%;
    display: flex;
    flex-wrap: wrap;
		float: left;
    clear: none;
    &.active .LevelTwoMenuLink {
      color: $themeColor;
    }
	}
}
/*** Simple Menu CSS ***/
/*** Mobile Navigation ***/
#MenuIcon{
    position: absolute;
    display: none;
    width: 80px;
    height: 80px;
    z-index: 99998;
    top: 48px;
    right: 0px;
    cursor: pointer;
    overflow: hidden;
    span{
      position: absolute;
      display: block;
      height: 5px;
      width: 45%;
      left: 50%;
      background-color: #fff;
      @include transform(translateX(-50%) rotate(0deg));
      @include transition($t1);
    }
    p{
      font-size: 0.8em;
      color: $textLight;
      text-transform: uppercase;
      font-weight: lighter;
      position: absolute;
      left: 50%;
      @include transform(translate(-50%));
      bottom: 12px;
    }
    span:nth-child(1){
      top:22px;
    }
    span:nth-child(2){
      top: 33px;
    }
    span:nth-child(3){
      top: 44px;
    }
    span:nth-child(4){
      top: 55px;
    }
}

.mobileNavBg {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.4);
	z-index: 6005;
	visibility: hidden;
	@include transition(all 1s);
	opacity: 0;
}

/*** Menu ***/
#MenuBg{
  position: absolute;
  width: 0%;
  height: 300%;
  left: 50%;
  top: 50%;
  background-color: $themeColor;
  @include transform(translate(-50%, -50%) rotate(-45deg));
  @include transition(all 1s ease-in-out);
  display: none !important;
}
#MenuDark{
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 60, 99, 0.9);
}
#MobileNavigation{
  background-color: $mainColor;
  @include transition(all 1s);
  position: fixed;
  z-index: 9999;
  top: 0px;
  right: -40%;
  height: 100%;
  overflow: hidden;
  width: 40%;
  max-width: 500px;
  opacity: 0;
  .toggleNav{
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
    color: #eee;
    font-size: 27px;
    border: none;
    outline: none;
  }
  .TopLevel{
    @include transform($center);




    padding: 10px 0px;
    position: absolute;
    display: inline-block;
    left: 50%;
    width: 95%;
    max-height: 75%;
    top: 50%;
    max-width: 300px;
    overflow-y: auto;
    text-align: left;
    border-radius: 3px;
    font-size: 0.8em;




    &:before{
      content: '';
      position: absolute;
      display: block;
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      opacity: 0.6;
      height: 50px;
      width: 50px;
      top: -20px;
      left: -20px;
    }
  }
  ul{
    ul{
      display: none;
      background: rgba(0, 0, 0, 0.05);
    }
    li{
      position: relative;
      a{
        color: $textLight;
        text-align: left;
        font-size: 1em;
        font-family: $font-stack;
        width: 100%;
        display: block;
        padding: 10px 34px 10px 20px;
        letter-spacing: 2px;
        text-decoration: none !important;
        @include transition($t1);
        &:hover{
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
.activeMobileMenu{
  overflow: hidden;
  #MobileNavigation{
  @include box-shadow(0px 0px 100px 0px rgba(0,0,0,0.7));
    display: block;
    right: 0px;
    opacity: 1  !important;
    margin-top: 0px !important ;
    height: 100%;
  }
	.mobileNavBg {
		visibility: visible;
		opacity: 1;
	}
  #MenuBg{
    width: 200%;
    height: 300%;
  }
   #MenuIcon {
      span{
        background-color: #fff !important;
      }
      span:nth-child(1){
        top: 35px;
        @include transform(translateX(-50%)  rotate(45deg));
      }
      span:nth-child(3){
        @include transform(translateX(-50%)  rotate(-45deg));
        top: 35px;
      }
      span:nth-child(2){
        margin-left: 100px !important;
        opacity: 0 !important;
        width: 50%;
      }
      span:nth-child(4){
        opacity: 0 !important;
      }
  }
}
@media all and (max-width: 800px)  {
  #MobileNavigation{
    right: -100% !important;
    width: 100% !important;
    max-width: none !important;
  }
  .activeMobileMenu{
    #MobileNavigation{
      right: 0px !important;
    }
  }
  #MobileNavigation .TopLevel {
    top: 131px;
    @include transform(translate(-50%, 0%));
    max-height: calc(100% - 150px);
  }

}
