/* -------------------- General Site layouts -------------------- **/
*{
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

body{
	font-family: $font-stack;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}

/*** General classes ***/

.colourBar {
	display: flex;
	height: 10px;
	width: 100%;
	.bar {
		flex-basis: 33%;
		flex-grow: 1;

		&:nth-child(1) {
			background-color: $mainColor2;
		}
		&:nth-child(2) {
			background-color: $themeColor;
		}
		&:nth-child(3) {
			background-color: $mainColor;
		}
	}
}

/*** Page ***/


.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}


.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}

.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $mainColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #fff;
	padding: 0 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}

.MainMenuClose{
	display: none !important;
}

.Page .mainContainer {
	position: relative;
	overflow: hidden;
}

.sidebarLinks {
	position: absolute;
    right: 0;
	top: 50px;
	z-index: 7;
	display: none;

	@include bp($bp-xs) {
		display: block;
	}
}

.sidebarItem {
	display: block;
	background: $mainColor2;
	width: 275px;
	margin: 12px 0;
	transform: translateX(215px);
	@include transition($t1);

	&:nth-child(2) {
		background: $themeColor;
	}

	&:nth-child(3) {
		background: $mainColor;
	}

	&:hover {
		transform: translateX(0);
	}

	a {
		display: flex;
		align-items: center;
		padding: 15px 65px 15px 20px;
		background: url(../images/watermark_small.png) no-repeat right center/contain;
		color: #fff;
		font-family: $font-poynter;

		img {
			margin-right: 20px;
			max-height: 25px;
		}
	}
}

.mainContent{
	padding: 50px 60px 50px 20px;	
	font-size: 1.1em;
	position: relative;
	z-index: 1;

	@include bp($bp-m) {
		padding: 100px 60px 100px 20px;
	}

	@include bp($bp-mlg) {
		padding: 100px 20px;
	}


	&:after{
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		background: url(../images/watermark.png) no-repeat center right/350px auto;
		right: -195px;
		top: -90px;
		.SixthFormPage & {
			background: url(../images/watermark.png) no-repeat center right/300px auto;
    		right: 0px;
		}
	}
	& > * {
		position: relative;
		z-index: 2;
	}

	ul{ //** Change template
		margin: 1em 2em;
		li{
			list-style: square !important;
		}
	}

	form {
		max-width: 500px;
		margin-bottom: 50px;
	}

}

.pageTitle {
	margin-bottom: 35px;

	&.subheading {
		padding-top: 50px;

		@include bp($bp-m) {
			padding-top: 100px;
		}
	}
}

.pageSubtitle {
	color: $mainColor;
	font-family: $font-poynter;
	font-size: 1.4em;
	line-height: 1.3em;
	text-align: center;
	margin-bottom: 35px;
}	

.columnContent {
	margin-bottom: 40px;

	@include bp($bp-xs) {
		column-count: 2;
		column-gap: 40px;
	}
}

.separatorImages {
	display: flex;
	justify-content: space-between;
	padding-bottom: 50px;
	height: 390px;
	clear: left;
}

.separatorImg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 32%;
}

.footBanner {
	display: flex;
	align-items: flex-start;
	width: 100%;
	max-height: 500px;
	overflow: hidden;
	img {
		width: 100%;
	}
}

.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 1em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    font-weight: lighter; 
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

//** Change template - duplicate inputs



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		clear: both;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
		}
		
		@include border-radius(3px);

	    background-color: $mainColor;

		 &:hover{ //** Change Template
	    	    background-color: darken($mainColor, 20%);
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


/*
|===============================================================
|	Loading Screen
|===============================================================
*/
@keyframes dotOne {
	0%   {color: rgb(255,255,255);}
	100% {color: $themeColor;}
 }
 @keyframes dotTwo {
	0%   {color: rgb(255,255,255);}
	100% {color: $themeColor;}
 }
 @keyframes dotThree {
	0%   {color: rgb(255,255,255);}
	100% {color: $themeColor;}
 }
 .dot-1{
	 font-size: 2em;
	animation: dotOne 200ms linear 0ms infinite alternate;
 }
 .dot-2{
	 font-size: 2em;
	animation: dotTwo 200ms linear 100ms infinite alternate;
 }
 .dot-3{
	 font-size: 2em;
	animation: dotThree 200ms linear 200ms infinite alternate;
 }
 .pace {
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999999;
  transform: translate3d(0, -50px, 0);
  transition: transform .5s ease-out;
 }
 .pace.pace-active {
  transform: translate3d(0, 0, 0);
 }
 .pace .pace-progress {
  display: block;
  position: fixed;
  z-index: 999999999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
  background: $themeColor;
  pointer-events: none;
 }
 .loadingSection{
	 position: fixed;
	 top: 0px;
	 left: 0px;
	 width: 100%;
	 height: 100%;
	 background-color: #000;
	z-index: 99999999;
	text-align: center;
 }
 .centerLoader{
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 img{width: 30px;}
	 @include transform($center);
 }
 .loadingText{
	 color: #efefef;
	text-transform: uppercase;
	font-size: 0.5em;
	letter-spacing: 4px;
	font-weight: bold;
	padding: 10px;
 }
 .Loading{
	 overflow: hidden !important;
	 height: 100vh;
	 .banner{
		 opacity: 0 !important;
	 }
 }

/*
|===============================================================
|	Uploads
|===============================================================
*/

.fileUpload {
	display: block;
	position: relative;
	border: 2px solid $lightGrey;
	padding: 7px 20px;
    margin-bottom: 20px;
	color: $mainColor;
	font-size: 1.2em;
	text-transform: uppercase;
	font-weight: 600;
	@include transition($t1);
	background: linear-gradient(to left, #fff 34%, $mainColor 65%);
	background-size: 300% 100%;
	background-position: right bottom;

	&:hover {
		color: #fff;
		border-color: $mainColor;
		background-position: left bottom;

		&:after {
			color: #fff;
		}
	}

	&:after {
		content: '\f054';
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		color: $themeColor;
		position: absolute;
		right: 20px;
		@include transition($t1);
	}
}

//**Vacancy Page
.vacancyHeading {
	cursor: pointer;
}
.vacancyText {
	max-height: 0;
	overflow: hidden;
	@include transition($t1);

	&.active {
		max-height: 2000px;
	}
}

//**Vacancy Page


.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 20%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 
/** General Site layouts **/

.SixthFormPage .pageTitle {
	font-size: 1.8em;
    letter-spacing: 9px;
}

.sixthFormBox {
	display: flex;
	justify-content: center;
	flex-wrap:wrap;
	
	@include bp($bp-m) {
		
	}
}

.sixthFormInset {
	width: 280px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 20px;

	@include bp($bp-m) {
		margin-bottom: 0;
	}

	.btn {
		width: 100%;
		display: none;

		@include bp($bp-m) {
			display: inline-block;
		}
	}
}

.sixthFormContent {
	width: 500px;
	max-width: 100%;
	padding: 0 20px;

	@include bp($bp-m) {
		padding: 0 0 0 100px;
		width: 500px;
		max-height: 340px;
		overflow-y: scroll;
		padding-right: 10px;
	}
}


.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -9px;
    background-color: #3d3a61;
    padding: 7px;
    left: 20px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn, 
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #56b4b9;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #56b4b9;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: $themeColor;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: darken($themeColor, 20%);
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/




/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}




/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/
.LogoSlide{
	padding: 5%;
}
.LogoSlideInner{
	height: 120px;
}
.LogoSlideImage{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 120px;
	height: 120px;
	@include transform($center);
	@include border-radius(50%);
	background-position: center;
	background-image: url(../images/bg.jpg);
	background-size: contain;
	overflow: hidden;
	background-repeat: no-repeat;
	@include box-shadow(0px 0px 10px 0px rgba(0,0,0, 0.4));
}
/* -------------------- Site Build -------------------- **/