.academyTitle {
    position: absolute;
    bottom: 60px;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    line-height: 1.8em;
    &:after {
        display: none;
    }

    @include bp($bp-s, false) {
        font-size: 1.3em;
        letter-spacing: 5px;
        line-height: 1.4em;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.titleTwo {
    display: block;
    color: $themeColor;
}

.AcademyPage {
    .mainContent{
        padding: 50px 20px;	
        font-size: 1.1em;
        position: relative;
        z-index: 3;
        &:after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background: url(../images/watermark.png) no-repeat center left/350px auto;
            left: 35px;
            top: 0;
        }

        @include bp($bp-s) {
            padding: 100px 20px 100px 300px;
        }   
    }

    .insetBox {
        position: absolute;
        left: 10px;
        top: -120px;
        height: 210px;
        width: 180px;
        overflow: hidden;
        display: none;
        align-items: center;
        justify-content: center;

        @include bp($bp-s) {
            display: flex;
        }

        @include bp($bp-m) {
            height: 250px;
            width: 250px;
        }
        
        @include bp($bp-lg) {
            height: auto;
            width: auto;
            left: -150px;
        }
    }

    .academySecond {
        padding: 0 20px 50px;

        @include bp($bp-s) {
            padding: 0 430px 140px 20px;
        }
    }

    #academySliderContainer {
        position: relative;
    }

    .academyControls {
        position: absolute;
        z-index: 10;

        a {
            color: #fff;
            display: block;
            background: $themeColor;
            padding: 6px 12px;
        }

        &.left {
            left: 50px;
            bottom: 0;
            transform: translateY(50%);
            a {
                padding: 6px 14px 6px 12px;
            }
        }
        &.right {
            right: 50px;
            top: 0;
            transform: translateY(-50%);
            a {
                padding: 6px 12px 6px 14px;
            }
        }
    }

    .applyLink {
        position: relative;        
        height: 250px;
        width: 345px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 auto;

        @include bp($bp-m) {
            position: absolute;
            top: 70px;
            right: 0;
            margin: 0;
            height: 73%;
        }
    }

    .applicationLink.btn {
        color: #fff;
        background: $themeColor;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        border: 1px solid $themeColor;
        @include transition($t1);

        &:hover {
            color: $themeColor;
            background: #fff;
        }
    }
}

.academyStrip {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include bp($bp-m) {
        flex-direction: row;
    }
}

.academyClass {
    flex-basis: 33.3%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 460px;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($mainColor2, 0.6);
        mix-blend-mode: multiply;    
        @include transition($t1);
    }

    &:hover:before {
        background: rgba($mainColor2, 0.8);
    }

    &:after {
        content: '';
        position: absolute;
        background: url(../images/watermark_small.png) no-repeat bottom left/100% auto;
        width: 275px;
        height: 295px;
        bottom: -5px;
        left: -50px;
    }

    &:nth-child(2){
        &:before {
            background: rgba($themeColor, 0.8);
        }
        &:hover:before {
            background: rgba(darken($themeColor, 10%), 1);
        }
    }

    &:nth-child(3){
        &:before {
            background: rgba($mainColor, 0.6);
            
        }

        &:hover:before {
            background: rgba($mainColor, 0.8);
        }
    }

    .footerTitle {
        position: relative;
        z-index: 6;
        font-size: 1.6em;
        line-height: 1.6em;
        letter-spacing: 12px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        font-family: $font-trajan;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
}

