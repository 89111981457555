/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
table.calendar-widget-table {
	width: 100%;
	padding-bottom: 50px;
	margin:0 0 2em;
	border: 0;
	thead {
		border-bottom: 1px solid $lightGrey;
		tr {
			th {
				border: none;
                font-size: 1.8em;
                
				.show-month, .next, .prev {
                    color: $textDark;
                    font-family: $font-poynter;
                    text-transform: uppercase;
					font-size: 0.8em;

					@include bp($bp-xs) {
						font-size: 1em;
					}
				}

				.next, 
				.prev {
					font-size: 0.7em;
				}
				
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color:$mainColor;
		}
	}
	tbody{
		.calendar-day {
            text-align: center;
            background-color: transparent;
			color: $textDark;
			border-top: 1px solid $textLight;
			border-bottom: 1px solid $textLight;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
                padding: 16px 0;
                font-size: 1.4em;
				position: relative;
				text-align:center;
				.calEventPopup {
					position: absolute;
					top:40px;
					padding:20px;
					border:2px solid $themeColor;
                    background-color: #fff;
                    color: $textDark!important;
					z-index: 2;
                    width: 180px;
                    text-align: center;
					display:none;
					font-size: 0.8em;
					.dayTitle {
						color: $mainColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color: $themeColor;
					}

				}
				&.hasEvent:hover {
					.calEventPopup {
						display:inline-block;
					}
				}
				&.out-of-month.hasEvent:hover {
					.calEventPopup {
						display:none;
					}
				}
				&:nth-child(1) .calEventPopup { left:0; }
				&:nth-child(2) .calEventPopup { left:-20px; }
				&:nth-child(3) .calEventPopup { left:-40px; }
				&:nth-child(4) .calEventPopup { left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { right:-40px; }
				&:nth-child(6) .calEventPopup { right:-20px; }
				&:nth-child(7) .calEventPopup { right:0px; }
			}
		}
		.hasEvent {
			background-image: url(../images/calendarIcon.svg);
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 15px 15px;
		}
		.today {
            color: #fff;
			background: $mainColor;
			font-weight: 500;
		}
		.hasEvent {
			background-image: url(../images/calendarIcon.svg);
			background-repeat:no-repeat;
			background-position:7px 7px;
			background-size: 15px 15px;
		}
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			opacity:0.4;
		}
	} 
	.calendar-day:hover, .selected {
		background-color: $mainColor !important;
		color: #FFFFFF !important;
	}
}

.calendarWidget{
   /* padding: 10px;*/
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

.calendarContent {
	padding-bottom: 10px;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: $mainColor;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    &:hover{
        background-color: darken($mainColor, 10%) !important;
    }
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
		color: #fff;
		display: flex;
		align-items: center;
		position: relative;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;
			
			// Not sure why animation($t1) did not workkj
		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
		margin-top: 10px;
    	color: #fff;
    	background-color: $mainColor2;
    	@include transition($t1);
    	&:hover{
    		background-color: darken($mainColor2, 20%) !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

